<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 249 248.984">
        <path id="Icon_material-question-answer" data-name="Icon material-question-answer" d="M500.089-479.537H475.2v112.086H313.238v24.89a12.445,12.445,0,0,0,12.445,12.446H462.675l49.782,49.781V-467.092A12.446,12.446,0,0,0,500.089-479.537Zm-49.781,74.672V-516.873a12.445,12.445,0,0,0-12.446-12.445H275.9a12.445,12.445,0,0,0-12.445,12.445V-342.5l49.781-49.782H437.785a12.445,12.445,0,0,0,12.492-12.4v-.094Z" transform="translate(-263.457 529.318)" fill="#5a8cdb"/>
    </icon-base>
</template>

<script>
export default {
name: 'iconQuestionAnswer',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
    type: Number,
    default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    enableBackground: {
    type: Boolean
    }
}
}
</script>